// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/utils';
import * as Actions from './actions';

const initialState = {
  // Get All AgencyLevel
  isFirstGetAllAgencyLevel: false,
  isGetAllAgencyLevelRequest: false,
  isGetAllAgencyLevelSuccess: false,
  isGetAllAgencyLevelFailure: false,
  getAllAgencyLevelState: {},
  // Create AgencyLevel
  isCreateAgencyLevelRequest: false,
  isCreateAgencyLevelSuccess: false,
  isCreateAgencyLevelFailure: false,
  // Update AgencyLevel
  isUpdateAgencyLevelRequest: false,
  isUpdateAgencyLevelSuccess: false,
  isUpdateAgencyLevelFailure: false,
  // Delete AgencyLevel
  isDeleteAgencyLevelRequest: false,
  isDeleteAgencyLevelSuccess: false,
  isDeleteAgencyLevelFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All agency levels
    [Actions.getAllAgencyLevelsRequest]: (state) => ({
      ...state,
      isGetAllAgencyLevelRequest: true,
      isGetAllAgencyLevelSuccess: false,
      isGetAllAgencyLevelFailure: false,
    }),
    [Actions.getAllAgencyLevelsSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllAgencyLevel: true,
      isGetAllAgencyLevelRequest: false,
      isGetAllAgencyLevelSuccess: true,
      isGetAllAgencyLevelFailure: false,
      getAllAgencyLevelState: payload,
    }),
    [Actions.getAllAgencyLevelsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllAgencyLevelRequest: false,
      isGetAllAgencyLevelSuccess: false,
      isGetAllAgencyLevelFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create agency levels
    [Actions.createAgencyLevelsRequest]: (state) => ({
      ...state,
      isCreateAgencyLevelRequest: true,
      isCreateAgencyLevelSuccess: false,
      isCreateAgencyLevelFailure: false,
    }),
    [Actions.createAgencyLevelsSuccess]: (state, { payload }) => {
      const getAllAgencyLevelStateTmp = handleUpdateDataList(state.getAllAgencyLevelState, payload.data, 'create');

      return ({
        ...state,
        isCreateAgencyLevelRequest: false,
        isCreateAgencyLevelSuccess: true,
        isCreateAgencyLevelFailure: false,
        getAllAgencyLevelState: { ...getAllAgencyLevelStateTmp },
      });
    },
    [Actions.createAgencyLevelsFailure]: (state, { payload }) => ({
      ...state,
      isCreateAgencyLevelRequest: false,
      isCreateAgencyLevelSuccess: false,
      isCreateAgencyLevelFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateAgencyLevels]: (state) => ({
      ...state,
      isCreateAgencyLevelRequest: false,
      isCreateAgencyLevelSuccess: false,
      isCreateAgencyLevelFailure: false,
    }),
    // #endregion
    // #region : Update agency levels
    [Actions.updateAgencyLevelsRequest]: (state) => ({
      ...state,
      isUpdateAgencyLevelRequest: true,
      isUpdateAgencyLevelSuccess: false,
      isUpdateAgencyLevelFailure: false,
    }),
    [Actions.updateAgencyLevelsSuccess]: (state, { payload }) => {
      const getAllAgencyLevelStateTmp = handleUpdateDataList(state.getAllAgencyLevelState, payload.data, 'update');

      return ({
        ...state,
        isUpdateAgencyLevelRequest: false,
        isUpdateAgencyLevelSuccess: true,
        isUpdateAgencyLevelFailure: false,
        getAllAgencyLevelState: { ...getAllAgencyLevelStateTmp },
      });
    },
    [Actions.updateAgencyLevelsFailure]: (state, { payload }) => ({
      ...state,
      isUpdateAgencyLevelRequest: false,
      isUpdateAgencyLevelSuccess: false,
      isUpdateAgencyLevelFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateAgencyLevels]: (state) => ({
      ...state,
      isUpdateAgencyLevelRequest: false,
      isUpdateAgencyLevelSuccess: false,
      isUpdateAgencyLevelFailure: false,
    }),
    // #endregion
    // #region : Delete agency levels
    [Actions.deleteAgencyLevelsRequest]: (state) => ({
      ...state,
      isDeleteAgencyLevelRequest: true,
      isDeleteAgencyLevelSuccess: false,
      isDeleteAgencyLevelFailure: false,
    }),
    [Actions.deleteAgencyLevelsSuccess]: (state, { payload }) => {
      const getAllAgencyLevelStateTmp = handleUpdateDataList(state.getAllAgencyLevelState, payload, 'delete');

      return ({
        ...state,
        isDeleteAgencyLevelRequest: false,
        isDeleteAgencyLevelSuccess: true,
        isDeleteAgencyLevelFailure: false,
        getAllAgencyLevelState: { ...getAllAgencyLevelStateTmp },
      });
    },
    [Actions.deleteAgencyLevelsFailure]: (state, { payload }) => ({
      ...state,
      isDeleteAgencyLevelRequest: false,
      isDeleteAgencyLevelSuccess: false,
      isDeleteAgencyLevelFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteAgencyLevels]: (state) => ({
      ...state,
      isDeleteAgencyLevelRequest: false,
      isDeleteAgencyLevelSuccess: false,
      isDeleteAgencyLevelFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetDeleteAgencyLevels]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
