const usLangInventory = {
  'inventory.helmet': 'List of inventorys',
  'inventory.breadcrumb.title': 'List of inventorys',
  'inventory.breadcrumb.parent': 'Sell',
  'inventory.modal.create.title': 'Add inventory',
  'inventory.modal.create.confirm.title': 'Confirm new inventorys',
  'inventory.modal.create.confirm.description': 'You definitely want to add this inventory?',
  'inventory.modal.detail.title': 'the detail information of inventory',
  'inventory.modal.update.title': 'Update inventory information',
  'inventory.modal.update.confirm.title': 'Confirmation of inventory information updates',
  'inventory.modal.update.confirm.description': 'You definitely want to update this inventory information?',
  'inventory.modal.delete.title': 'inventory deletion confirmation',
  'inventory.modal.delete.description': 'You definitely want to delete this inventory?',
  'inventory.modal.reset-password.title': 'Reset Password',
  'inventory.noti.create.success': 'Add successful inventorys!',
  'inventory.noti.create.failure': 'Add unsuccessful inventorys!Please try again later.',
  'inventory.noti.update.success': 'Update the inventory successfully!',
  'inventory.noti.update.failure': 'Update the inventory unsuccessful!Please try again later.',
  'inventory.noti.delete.success': 'Delete inventorys successfully!',
  'inventory.noti.delete.failure': 'Delete inventorys failed!Please try again later.',
  'inventory.noti.reset-password.success': 'Retite a successful inventory password!',
  'inventory.noti.reset-password.failure': 'Reset the inventory password failed!Please try again later.',
  'inventory.btn-create': 'Create',
  'inventory.error-min-length': 'Minimum $x character!',
  'inventory.error-max-length': 'Maximum $x character!',
  'inventory.code.col': 'inventory code',
  'inventory.code.label': 'inventory code',
  'inventory.code.placeholder': 'Enter the inventory code',
  'inventory.barcode.col': 'Barcode',
  'inventory.barcode.label': 'Barcode',
  'inventory.barcode.placeholder': 'Enter the barcode',
  'inventory.categories.col': 'inventory line',
  'inventory.categories.label': 'inventory line',
  'inventory.categories.placeholder': 'Choose a inventory line',
  'inventory.categories.error.required': 'Please choose the inventory line!',
  'inventory.name.col': "inventory's name",
  'inventory.name.label': "inventory's name",
  'inventory.name.placeholder': 'Enter the name of the inventory',
  'inventory.name.error.required': 'Please enter the inventory name!',
  'inventory.desc.col': 'Describe',
  'inventory.desc.label': 'inventory Description',
  'inventory.desc.placeholder': 'Enter the inventory description',
  'inventory.unit.col': 'Unit',
  'inventory.unit.label': 'Basic unit',
  'inventory.unit.placeholder': 'Enter the basic unit',
  'inventory.unit.error-required': 'Please enter the basic unit!',
  'inventory.warehouse.col': 'Warehouses',
  'inventory.price.label': 'Price (VND)',
  'inventory.price.placeholder': 'Enter the selling price',
  'inventory.price.error-required': 'Please enter the selling price!',
  'inventory.price.invalid': 'Enter the price greater than 0!',
  'inventory.units.name.label': 'Unit name',
  'inventory.units.title.label': 'Unit title',
  'inventory.units.name.placeholder': 'Enter the name of the unit',
  'inventory.units.name.error-required': 'Please enter the name of the unit!',
  'inventory.units.title.placeholder': 'Enter the title of the unit',
  'inventory.units.title.error-required': 'Please enter the title of the unit!',
  'inventory.units.number.label': 'Exchange value',
  'inventory.units.number.placeholder': 'Enter the conversion value',
  'inventory.units.number.error-required': 'Please enter the conversion value!',
  'inventory.units.number.error-invalid': 'The conversion value must be greater than 0!',
  'inventory.units.price.label': 'Price (VND)',
  'inventory.units.price.placeholder': 'Enter the selling price',
  'inventory.units.price.error-required': 'Please enter the selling price!',
  'inventory.units.code.label': 'inventory code',
  'inventory.units.code.placeholder': 'Enter the inventory code',
  'inventory.units.barcode.label': 'Barcode',
  'inventory.units.barcode.placeholder': 'Enter the barcode',
  'inventory.image.col': 'Image',
  'inventory.image.label': 'Upload',
  'inventory.image.label-2': 'inventory image',
  'inventory.image.error-required': 'Please upload the inventory photo!',
  'inventory.btn-add-units-items': 'Add unit',
  'inventory.serial.label': 'Use serial/imei',
  'inventory.quantity.label': 'Apply the number of warehouses',
  'inventory.expiry.label': 'Apply expiry date',
  'inventory.cost.label': 'Cost',
  'inventory.discount.label': 'Origin price',
  'inventory.cost.placeholder': 'Input capital cost',
  'inventory.trending.label': 'inventory trending',
  'inventory.discount.placeholder': 'Input origin price',
  'inventory.wholesale.price.label': 'Wholesale price',
  'inventory.wholesale.price.placeholder': 'Enter wholesale price',
  'inventory.wholesale.quantity.label': 'Wholesale applicable quantity',
  'inventory.wholesale.quantity.placeholder': 'Enter wholesale applicable quantity',
  'inventory.wholesale.price.error-required': 'Please enter wholesale price!',
  'inventory.wholesale.price.invalid': 'Enter wholesale price greater than 0!',
  'inventory.wholesale.quantity.error-required': 'Please enter the quantity to which the wholesale price applies!',
  'inventory.wholesale.quantity.invalid': 'Enter a wholesale applicable quantity greater than 0!',
  'inventory.warehouse.label': 'Number of products in stock',

};

export default usLangInventory;
