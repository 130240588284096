const usLangAgency = {
  'agency.helmet': 'Dealer List',
  'agency.breadcrumb.title': 'Agency List',
  'agency.breadcrumb.parent': 'Sales',
  'agency.modal.create.title': 'Add agent',
  'agency.modal.create.confirm.title': 'Confirm add new agent',
  'agency.modal.create.confirm.description': 'Are you sure you want to add this agency?',
  'agency.modal.detail.title': 'Agency Details',
  'agency.modal.update.title': 'Update agent information',

  'agency.modal.update.confirm.title': 'Confirm the update of the agency information',
  'agency.modal.update.confirm.description': 'Are you sure you want to update this agency information?',
  'agency.modal.delete.title': 'Confirm agency deletion',
  'agency.modal.delete.description': 'Are you sure you want to delete this agency?',

  'agency.noti.create.success': 'Add agent successfully!',
  'agency.noti.create.failure': 'Adding agent failed! Please try again later.',
  'agency.noti.update.success': 'Agency update successful!',
  'agency.noti.update.failure': 'Agency update failed! Please try again later.',
  'agency.noti.delete.success': 'Affiliate deletion successful!',
  'agency.noti.delete.failure': 'Agency deletion failed! Please try again later.',
  'agency.btn-create': 'Add',
  'agency.error-min-length': 'Minimum $x characters!',
  'agency.error-max-length': 'Maximum $x characters!',

  'agency.name.col': 'Agency name',
  'agency.name.label': 'Agency name',
  'agency.name.placeholder': 'Enter agency name',
  'agency.name.error.required': 'Please enter agency name!',
  'agency.desc.col': 'Description',
  'agency.desc.label': 'Agency Description',
  'agency.price.label': 'Agency price',
  'agency.desc.placeholder': 'Enter agent description',
  'agency.price.placeholder': 'Enter agent price',

  'agency.image.col': 'Image',
  'agency.image.label': 'Upload',
  'agency.image.label-2': 'Agency image',
  'agency.image.error-required': 'Please upload the agency photo!',

  'agency.level.col': 'Agency level',
  'agency.level.placeholder': 'Input agency level',
  'agency.name.product.label': 'Name product',
  'agency.price.product.label': 'Price product',
  'agency.discount.percentage.product.label': 'Discount percentage',
  'agency.general.discount.col': 'General discount',
  'agency.general.discount.placeholder': 'Enter agent general discount',
  'agency.search.placeholder': 'Search for discount unit',

};

export default usLangAgency;
