const vnLangDiscount = {
  'discount.helmet': 'Danh sách ưu đãi',
  'discount.breadcrumb.title': 'Danh sách ưu đãi',
  'discount.breadcrumb.parent': 'Bán hàng',
  'discount.modal.create.title': 'Thêm mã giảm giá',
  'discount.modal.create.confirm.title': 'Xác nhận thêm mã giảm giá mới',
  'discount.modal.create.confirm.description': 'Bạn chắc chắn muốn thêm mã giảm giá này?',
  'discount.modal.detail.title': 'Thông tin chi tiết mã giảm giá',
  'discount.modal.update.title': 'Cập nhật thông tin mã giảm giá',
  'discount.modal.update.confirm.title': 'Xác nhận cập nhật thông tin mã giảm giá',
  'discount.modal.update.confirm.description': 'Bạn chắc chắn muốn cập nhật thông tin mã giảm giá này?',
  'discount.modal.delete.title': 'Xác nhận xoá mã giảm giá',
  'discount.modal.delete.description': 'Bạn chắc chắn muốn xoá mã giảm giá này?',
  'discount.modal.reset-password.title': 'Đặt lại mật khẩu',
  'discount.noti.create.success': 'Thêm mã giảm giá thành công!',
  'discount.noti.create.failure': 'Thêm mã giảm giá không thành công! Vui lòng thử lại sau.',
  'discount.noti.update.success': 'Cập nhật mã giảm giá thành công!',
  'discount.noti.update.failure': 'Cập nhật mã giảm giá không thành công! Vui lòng thử lại sau.',
  'discount.noti.delete.success': 'Xoá mã giảm giá thành công!',
  'discount.noti.delete.failure': 'Xoá mã giảm giá không thành công! Vui lòng thử lại sau.',
  'discount.noti.reset-password.success': 'Đặt lại mật khẩu mã giảm giá thành công!',
  'discount.noti.reset-password.failure': 'Đặt lại mật khẩu mã giảm giá không thành công! Vui lòng thử lại sau.',
  'discount.btn-create': 'Thêm',
  'discount.error-min-length': 'Tối thiểu $x ký tự!',
  'discount.error-max-length': 'Tối đa $x ký tự!',
  'discount.code.col': 'Mã giảm giá',
  'discount.code.label': 'Mã giảm giá',
  'discount.code.placeholder': 'Nhập mã giảm giá',
  'discount.barcode.col': 'Mã vạch',
  'discount.barcode.label': 'Mã vạch',
  'discount.barcode.placeholder': 'Nhập mã vạch',
  'discount.categories.col': 'Dòng mã giảm giá',
  'discount.categories.label': 'Dòng mã giảm giá',
  'discount.categories.placeholder': 'Chọn dòng mã giảm giá',
  'discount.categories.error.required': 'Vui lòng chọn dòng mã giảm giá!',
  'discount.name.col': 'Tên mã giảm giá',
  'discount.name.label': 'Tên mã giảm giá',
  'discount.name.placeholder': 'Nhập tên mã giảm giá',
  'discount.name.error.required': 'Vui lòng nhập tên mã giảm giá!',
  'discount.desc.col': 'Mô tả',
  'discount.desc.label': 'Mô tả mã giảm giá',
  'discount.desc.placeholder': 'Nhập mô tả mã giảm giá',
  'discount.unit.col': 'Đơn vị',
  'discount.unit.label': 'Đơn vị tính cơ bản',
  'discount.unit.placeholder': 'Nhập đơn vị tính cơ bản',
  'discount.unit.error-required': 'Vui lòng nhập đơn vị tính cơ bản!',
  'discount.price.col': 'Giá bán',
  'discount.price.label': 'Giá bán (vnđ)',
  'discount.price.placeholder': 'Nhập giá bán',
  'discount.price.error-required': 'Vui lòng nhập giá bán!',
  'discount.price.invalid': 'Nhập giá lớn hơn 0!',
  'discount.units.name.label': 'Tên đơn vị',
  'discount.units.name.placeholder': 'Nhập tên đơn vị',
  'discount.units.name.error-required': 'Vui lòng nhập tên đơn vị!',
  'discount.units.number.label': 'Giá trị quy đổi',
  'discount.units.number.placeholder': 'Nhập giá trị quy đổi',
  'discount.units.number.error-required': 'Vui lòng nhập giá trị quy đổi!',
  'discount.units.number.error-invalid': 'Giá trị quy đổi phải lớn hơn 0!',
  'discount.units.price.label': 'Giá bán (vnđ)',
  'discount.units.price.placeholder': 'Nhập giá bán',
  'discount.units.price.error-required': 'Vui lòng nhập giá bán!',
  'discount.units.code.label': 'Mã giảm giá',
  'discount.units.code.placeholder': 'Nhập mã giảm giá',
  'discount.units.barcode.label': 'Mã vạch',
  'discount.units.barcode.placeholder': 'Nhập mã vạch',
  'discount.image.col': 'Hình ảnh',
  'discount.image.label': 'Tải lên',
  'discount.image.label-2': 'Hình ảnh mã giảm giá',
  'discount.image.error-required': 'Vui lòng tải lên ảnh mã giảm giá!',
  'discount.btn-add-units-items': 'Thêm đơn vị tính',
  'discount.type.label': 'Hình thức giảm',
  'discount.number.percent.label': 'Phần trăm giảm',
  'discount.number.label': 'Số tiền giảm',
  'discount.quantity.label': 'Giới hạn số lượng',
  'discount.duration.label': 'Thời gian hiệu lực',
  'discount.maximum.label': 'Số tiền giảm tối đa',
  'discount.condition.label': 'Điều kiện áp dụng (tổng tiền >)',
  'discount.condition-product.label': 'Điều kiện áp dụng (số lượng sản phẩm >)',
  'discount.start.label': 'Ngày bắt đầu',
  'discount.end.label': 'Ngày kết thúc',
  'discount.expiry.label': 'Ngày hết hạn',
  'discount.voucher.label': 'Voucher tích điểm',
  'discount.voucher.value-point': 'Số điểm đổi voucher',
  'discount.type.required': 'Vui lòng chọn kiểu giảm giá',
  'discount.start.required': 'Vui lòng nhập ngày bắt đầu',
  'discount.end.required': 'Vui lòng nhập ngày kết thúc',
  'discount.start.not-valid': 'Ngày bắt đầu không đúng định dạng',
  'discount.end.not-valid': 'Ngày kết thúc không đúng định dạng',
  'discount.money': 'Số tiền',
  'discount.percent': 'Phần trăm',
  'discount.gift': 'Quà tặng',
  'discount.gift.label': 'Tên quà tặng',
  'discount.voucher.value-gift': 'Chọn quà tặng',
  'discount.type-low-items-gift': 'Thấp',
  'discount.type-high-items-gift': 'Cao',
  'discount.type-option-items-gift': 'Tuỳ chọn',
  'discount.voucher.input-product': 'Chọn sản phẩm',
  'discount.voucher.input-number': 'Nhập số lượng',
  'discount.voucher.input-type-gift': 'Chọn loại quà tặng',
  'discount.voucher.order': 'Giảm giá đơn hàng',
  'discount.voucher.product': 'Giảm giá sản phẩm',
  'discount.remain.label': 'Số lượng còn lại',
  'discount.duplicate.label': 'Cho phép sử dụng nhiều khuyến mãi trùng lặp',

};

export default vnLangDiscount;
